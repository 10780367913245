import React from 'react'

import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import styles from './about.module.css'

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        title="Contact"
        description="The official website of Jarren Rocks. Designer of experiences and products."
      />

      <div className={styles.spread}></div>
      <Container>
        <div style={{ textAlign: 'center' }}>
          <div>
            <h1>Contact Me</h1>
            <p>
              j@jarren.rocks
              <br />
              <br />
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ContactPage
